<template>
  <div class="container">

    <div class="load-calculation mt-4 mb-4">
      <h4 class="text-center mb-4">Расчет нагрузки</h4>

      <div class="row mb-4">
        <div class="col-md-12">

          <button @click="$router.push('formed-load-calculation')" class="btn btn-primary">Сформированный расчет нагрузки</button>




        </div>
      </div>

      <DataTable :value="loadCalculation.loadCalculation" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 v-model:filters="filters"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <Column field="name" header="Название"></Column>
        <Column header="Название ОП">
          <template #body="{data}">

              {{ data.education_speciality_code }} {{data.education_speciality_name }}


          </template>
        </Column>
        <Column field="study_level_name" header="Уровень обучения">

          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="studyLevels" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{slotProps.value}}</span>
                <span v-else>{{slotProps.placeholder}}</span>
              </template>
              <template #option="slotProps">
                <span>{{slotProps.option}}</span>
              </template>
            </Dropdown>
          </template>

        </Column>
        <Column field="count_of_students" header="Количество студентов"></Column>
        <Column field="study_course" header="Курс"></Column>
        <Column field="semester" header="Семестр"></Column>
        <Column header="Часы">
          <template #body="{data}">
            {{data.hours}}
          </template>
        </Column>
        <Column header="Руководитель">
          <template #body="{data}">
            <Button label="Вакансия" @click="openAddSupervisorDialog(data.hours, data.education_program_id, data.study_level_id)"
                    v-bind:class="{ 'p-button-secondary': isHoursZero(data.education_program_id) }"/>

          </template>
        </Column>




      </DataTable>

      <Dialog v-model:visible="addSupervisorDisplay" :style="{width: '50vw'}">
        <template #header>
          <h5>Добавление руководителя к {{getEducationProgramName()}}</h5>
        </template>

        <div class="col-md-12 mt-4">


          <h4>Количество часов: {{getLoadCalculationHours()}}</h4>


          <div class="selection-supervisors-info">
            <div class="form-row" v-for="(selectionSupervisorsInfo, selectionSupervisorsInfoIndex) in loadCalculation.selectionSupervisorsInfos"
                 :key="selectionSupervisorsInfoIndex">



              <div class="remove-item__button d-flex flex-row-reverse">
                <i class="fa fa-times" @click="deleteRowSelectionSupervisorsInfo(selectionSupervisorsInfoIndex)"></i>
              </div>

            <div class="form-group row mt-4">
              <label for="pps_id" class="col-md-3 col-form-label">Руководитель</label>
              <div class="col-md-9" id="pps_id">
                <select class="form-control form-select"
                        @input="changeSelectionSupervisorsData(selectionSupervisorsInfo,'pps_id', $event)">
                  <option v-for="(item, index) in [{pps_id: 0, lastname: 'Выберите автора'}, ...loadCalculation.pps]"
                          :value="item.pps_id"
                          :selected="item.pps_id === selectionSupervisorsInfo.pps_id"
                          :key="index">{{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>
              <div class="form-group row mt-4">
                <label for="student_counts" class="col-md-3 col-form-label">Количество студентов</label>
                <div class="col-md-9" id="student_counts">

                  <input type="number" class="form-control" min="1" max="8"
                         placeholder="Количество студентов"
                         :value="selectionSupervisorsInfo.student_counts"
                         @input="changeSelectionSupervisorsData(selectionSupervisorsInfo,'student_counts', $event)">

<!--                  <InputNumber id="integeronly" showButtons-->
<!--                               decrementButtonClass="p-button-danger" decrementButtonIcon="pi pi-minus"-->
<!--                               incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" :step="1" v-model="value"-->
<!--                               @input="changeSelectionSupervisorsData(selectionSupervisorsInfo,'student_counts', $event)" :min="0" :max="8"/>-->
                </div>
              </div>
              <hr>


          </div>
          </div>

          <Button icon="pi pi-plus" @click="addRowSelectionSupervisorsInfo"/>



        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeAddSupervisorDialog"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitSelectionSupervisors"/>
        </template>
      </Dialog>


    </div>
  </div>
</template>


<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "LoadCalculation",
  components: {},
  data() {
    return {
      addSupervisorDisplay: false,
      loadCalculationHours: 0,
      studyLevelId: 0,
      educationProgramId: 0,
      filters: {

        'study_level_name': {value: null, matchMode: FilterMatchMode.EQUALS},



      },
      studyLevels: ['Бакалавриат', 'Магистратура (1 годичное,профильное)', 'Магистратура (1,5 годичное, профильное)',
        'Магистратура (2 годичное, научно-педагогическое)', 'Докторантура (научно-педагогическое направление)', 'MBA', 'EMBA'],
    }
  },
  computed: {
    ...mapState('loadCalculation', ['loadCalculation']),

  },

  methods: {
    ...mapActions('loadCalculation', ['GET_LOAD_CALCULATION', 'GET_PPS', 'POST_SELECTION_SUPERVISORS', 'PUT_SELECTION_SUPERVISORS', 'DELETE_SELECTION_SUPERVISORS']),
    ...mapMutations('loadCalculation', ['ADD_ROW_SELECTION_SUPERVISORS_INFO','DELETE_ROW_SELECTION_SUPERVISORS_INFO',
      'SET_SELECTION_SUPERVISORS_DATA', 'SET_SELECTION_SUPERVISORS_STUDY_LEVEL_ID', 'SET_SELECTION_SUPERVISORS_EDUCATION_PROGRAM_ID', 'SET_SELECTION_SUPERVISORS_DB']),
    getLoadCalculationHours(){

      let loadCalculationHours = this.loadCalculation.loadCalculation.find(i => i.hours == this.loadCalculationHours).hours

      console.log(loadCalculationHours, 'loadCalculationHours')

        return parseInt(loadCalculationHours) - parseInt((this.loadCalculation.selectionSupervisorsInfos.reduce((sum, item) => +item.student_counts + sum, 0) * 10))


    },


    isHoursZero(education_program_id){

      let loadCalculationHours = this.loadCalculation.loadCalculation.find(i => i.education_program_id == education_program_id).hours
      let selectionSupervisors = this.loadCalculation.loadCalculation.find(i => i.education_program_id == education_program_id).selection_supervisors
      let selectionSupervisorsHours = selectionSupervisors?.reduce((sum, item) => +item.student_counts + sum, 0) * 10

      console.log(loadCalculationHours, 'loadCalculationHours')
      console.log(selectionSupervisorsHours, 'selectionSupervisorsHours')
      let difference = loadCalculationHours - selectionSupervisorsHours
      console.log(difference, 'difference')
      if (difference == 0) {
        return true
      }
      else {
        return false
      }



    },

    getEducationProgramName() {
      let educationProgram = this.loadCalculation.loadCalculation.find(i=>i.education_program_id == this?.educationProgramId)

      return educationProgram.education_speciality_code + ' ' + educationProgram.education_speciality_name

    },
    openAddSupervisorDialog(hours, education_program_id, study_level_id) {
      this.loadCalculationHours = hours
      this.educationProgramId = education_program_id
      this.studyLevelId = study_level_id
      this.addSupervisorDisplay = true
      this.loadCalculation.selectionSupervisorsInfos.length = 0
      let loadCalculationOne = this.loadCalculation.loadCalculation.find(i=> i.education_program_id === education_program_id)

      console.log(loadCalculationOne, 'loadCalculationOne')

      this.SET_SELECTION_SUPERVISORS_DB(loadCalculationOne)


      console.log(this.loadCalculation.selectionSupervisorsInfos, 'selectionSupervisorsInfos')




    },
    closeAddSupervisorDialog(){
      this.addSupervisorDisplay = false
      this.loadCalculationHours = 0
      this.loadCalculation.selectionSupervisorsInfos.length = 0
      this.studyLevelId = 0
    },
    addRowSelectionSupervisorsInfo() {
      this.ADD_ROW_SELECTION_SUPERVISORS_INFO();
    },
    async deleteRowSelectionSupervisorsInfo(index) {
      let deleteId = this.loadCalculation.selectionSupervisorsInfos[index].id

      if(deleteId) {
        await this.DELETE_SELECTION_SUPERVISORS(deleteId);
        await this.GET_LOAD_CALCULATION()
      }




      await this.DELETE_ROW_SELECTION_SUPERVISORS_INFO(index);
    },
    changeSelectionSupervisorsData(item, property, e, val = 'value') {
      const value = e.target[val]

      let studyLevelId = this.studyLevelId
      let educationProgramId = this.educationProgramId
      this.SET_SELECTION_SUPERVISORS_DATA({item, property, value})
      this.SET_SELECTION_SUPERVISORS_STUDY_LEVEL_ID({item, studyLevelId})
      this.SET_SELECTION_SUPERVISORS_EDUCATION_PROGRAM_ID({item, educationProgramId})
    },
    async submitSelectionSupervisors() {

      //let selectionSupervisors = this.loadCalculation.loadCalculation.find(i => i.education_program_id == this.educationProgramId)

      let selectionSupervisors = this.loadCalculation.selectionSupervisorsInfos
      console.log(selectionSupervisors, 'selectionSupervisors')
      let putSelectionSupervisors = selectionSupervisors.filter(i => i.id != null)
      let postSelectionSupervisors = selectionSupervisors.filter(i => i.id == null)


      console.log(putSelectionSupervisors, 'putSelectionSupervisors')
      console.log(postSelectionSupervisors, 'postSelectionSupervisors')


      if (postSelectionSupervisors.length) {
        await this.POST_SELECTION_SUPERVISORS(postSelectionSupervisors).then(res => {
          if (res) {
            this.addSupervisorDisplay = false
            this.GET_LOAD_CALCULATION()

            this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
          } else {
            const errorText = res.errors[0].map(err => err.message).join('\n');
            this.$error({title: 'Добавление', text: errorText})
          }
        })
      }

      if (putSelectionSupervisors.length) {
        await this.PUT_SELECTION_SUPERVISORS(putSelectionSupervisors).then(res => {
          if (res) {
            this.addSupervisorDisplay = false
            this.GET_LOAD_CALCULATION()

            this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
          } else {
            const errorText = res.errors[0].map(err => err.message).join('\n');
            this.$error({title: 'Обновление', text: errorText})
          }
        })
      }





      this.studyLevelId = 0
      this.educationProgramId = 0
      this.loadCalculationHours = 0
    }
  },

  async mounted() {
    await this.GET_LOAD_CALCULATION()
    await this.GET_PPS()
  }
}
</script>

<style scoped>

</style>
